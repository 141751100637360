import Image from 'components/core/Image';
import { useTranslation } from 'next-i18next';

import Img from '../../../public/error.png';
import styles from './index.module.scss';

const Error = () => {
  const { t } = useTranslation('404');

  return (
    <div className={styles.container}>
      <Image
        src={Img}
        alt='Card'
        width={400}
        height={400}
      />
      <h1>{t('notFound')}</h1>
      <h5 className={styles.info}>{t('info')}</h5>
    </div>
  );
};

export default Error;
