import { NextPage } from 'next';
import Error from 'components/pages/Error';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import GeneralLayout from '../layouts/GeneralLayout';

const ErrorPage: NextPage = () => (
  <GeneralLayout
    title='Sorry Page Not Found'
    description='The page you are looking for has been deleted, or does not exist. '
    keywords='US gift cards, US Game Cards, Email Delivery, game card gift codes, game cards, iTunes, Google Play, Xbox, PSN, playstation, karma koin, league of legends, blizzard, eShop, Amazon, Skype'
  >
    <Error />
  </GeneralLayout>
);

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, [
      '404', 'layout', 'header', 'footer',
    ])),
  },
});

export default ErrorPage;
